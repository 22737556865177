import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Meta from 'vue-meta'
import router from './router'
import store from './store'
import "./assets/fonts/font_hk/iconfont.css";
import "./assets/fonts/font_ciguang/iconfont.css";
import "./assets/css/pc.css";
import VueAliplayerV2 from "vue-aliplayer-v2";

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAxios, axios)
Vue.use(VueAliplayerV2);
Vue.use(VuePlyr, {plyr: {}});
Vue.use(Meta)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
