<template>
<footer>
  <div class="hk-bottom">
    <template>
      <div>
        <el-divider><i class="el-icon-loading"></i></el-divider>
      </div>
    </template>
    <div class="hk-bottom-info">
        <div class="hk-bottom-logo">
            <img src="../assets/image/bot.png">
        </div>
        <div class="hk-bottom-info-learning">
            <div class="hk-bottom-info-learning-content">
                <div class="hk-bottom-title">慈光文化</div>
                <div class="hk-bottom-info-item">
                    <p>弘開覺路，普化群生。弘揚佛法永無止境，提供最方便的修行工具，一直以來都是我們努力的目標。</p>
                </div>
            </div>

            <div class="hk-bottom-info-learning-content">
                <div class="hk-bottom-title">關於我們</div>
                <ul class="hk-bottom-info-item">
                    <li 
                        v-for="(item, index) in aboutlist" 
                        :key="index"
                        class=""><el-link :href="item.url" target="_blank">{{ item.title }}</el-link></li>
                </ul>
            </div>
            <div class="hk-bottom-info-learning-content">
                <div class="hk-bottom-title">相關網站</div>
                <ul class="hk-bottom-info-item">
                    <li 
                        v-for="(item, index) in linklist" 
                        :key="index"
                        class=""><el-link :href="item.url" target="_blank">{{ item.title }}</el-link></li>
                </ul>
            </div>
        </div>
        
    </div>
  </div>
</footer>
</template>

<script>
export default {
  name: 'FooterView',
  props: {
    
  },
  data(){
      return{
         "linklist" : [
             {"title":"净公道影Youtbe","url":"https://www.youtube.com/@jinggongdaoying"},       
             {"title":"慈光讲堂Youtbe","url":"https://www.youtube.com/@ciguangculture"},     
        ],
         "aboutlist" : [
             {"title":"關於慈光","url":"index"},
             {"title":"社交平台","url":"live"},
        ],        
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
       .hk-bottom{
            padding-left:10px;
            padding-right:10px;            
        }
        .hk-bottom-logo{
            float: left;
            width:800px;
        }
        .hk-bottom-logo img{
            width:800px;
        }
        .hk-bottom-info{
            margin-bottom: 15px;      
        }
        .hk-bottom-title{
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-weight: 300;
        }
        .hk-bottom-info-item{
            line-height: 25px;
            margin-bottom: 15px; 
            
        }
        .hk-bottom-info ul li{
            float: left;
            margin-right:20px;
            margin-bottom: 15px;
        }
        .hk-bottom-info-learning-content{
            clear: both;  
            
        }
        .hk-bottom-info-item{
            
        }
        .hk-bottom-info-learning{
            float: left;
            width: 460px;
            line-height: 25px;
        }
</style>
