<template>
  <main>
    <div class="cg-search-content">
      <div class="cg-search">
          <span class="cg-search-title">搜索</span>
          <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select cg-search-input" clearable>
            <el-button slot="append" icon="el-icon-search" @click="onSubmit"></el-button>
          </el-input>
      </div>
      <div class="cg-tag tag-group">
        <span class="tag-group__title">分類：</span>
        <el-tag v-bind:class="[listQuery.aid == item.value ? tagSelectClass : '', tagClass]" effect="plain" v-on:click="gotoCate(item.value)"              
          v-for="(item, index) in categoryList"
          :key="index">
            {{ item.text }}   
        </el-tag>
      </div>
    </div>
    <template>
      <div>
        <el-divider><i class="el-icon-loading"></i></el-divider>
      </div>
    </template>
    <div class="cg-infolist">
        <template>
          <div class="cg-infolist-title">
            <span class="">{{ title }}</span>
          </div>
        </template>
        <div class="">
          <el-row>
            <el-col :span="6" 
            v-for="(item, index) in list" 
            :key="index">
              <router-link target="_blank" :to="{ path: `/essence/view/${item.id}` }">
                <el-card :body-style="{ padding: '0px' }" shadow="hover" class="cg-infolist-card">
                  <img :src="item.cover" class="image">
                  <div>
                    <span class="cg-infolist-card-title">{{ item.title }}</span>
                    <div class="bottom clearfix">
                      <time class="cg-infolist-card-time">{{ item.date }}</time>
                      <div class="cg-infolist-card-modelname">{{ item.cate_name }}</div>
                    </div>
                  </div>
                </el-card>
              </router-link>
            </el-col>
          </el-row>
        </div>
    </div>
    <div class="app-pagebar">
      <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        :page-sizes="pageSizeList"
        @pagination="changePage" />
    </div>
  </main>
</template>

<script>
import { getEssenceCategoryList, getEssenceTagList, getEssenceList } from '@/common/api_cg';
import Pagination from '@/components/Pagination'
export default {
  components: { Pagination },
  name: 'RaidoIndex',
  data(){
    return{
      title : '',
      tagSelectClass : 'cg-tag-item-select',
      tagClass : 'cg-tag-item',
      keyword: '',
      activePage:0,
      categoryList:[],
      list:[],
      isLoading:true,
      loadPage:false,
      refreshing:false,
      page:1,
      listQuery: {
        page: 1,
        pages: 1,
        limit: 40,
        aid:0
      },
      pageSizeList : [10, 20, 40, 100],
      total: 0,
    };
  },
  methods: {
    gotoCate(aid) {
      this.isLoading = true
      this.listQuery.page = 1
      this.listQuery.aid =  aid
      this.list = []
      this.fetchData()
    },
    fetchData(){
      this.list = []
      getEssenceList(this.listQuery).then(res => {
          for (let i = 0; i < res.data.rows.length; i++) {
            this.list.push(res.data.rows[i]); 
          }  
          this.listQuery.pages = res.data.page.pages
          this.isLoading = false
          this.loadPage = false
          this.total = res.data.page.total
      })   

    },
    changePage(params) {
      this.listQuery.page = params.page
      this.listQuery.limit = params.limit
      this.fetchData();
    },
    onSubmit() {
      if(this.keyword){
        this.listQuery.keyword = this.keyword
        this.fetchData()
      }
    }
  },
  created(){
        if(this.$route.params.aid){
            this.categoryValue = parseInt(this.$route.params.aid)
            this.listQuery = {
              aid: this.categoryValue,
            }
        }
        getEssenceCategoryList({}).then(res => {
            this.categoryList = res.data.rows
            this.fetchData()
        })   
        getEssenceTagList({}).then(res => {
            this.tagList = res.data.rows
        }) 
  },
    metaInfo: {
    title: '視頻-慈光讲堂',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' }
    ],
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
