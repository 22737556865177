<template>
  <div id="app">
    <Header />
    <div class="cg-container">
      <router-view></router-view>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "./views/Header.vue";
import Footer from "./views/Footer.vue";

export default {
  name: "app",
  components: {
    Header,
    Footer
  }
};
</script>

<style>

</style>
