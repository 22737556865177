export const public_v1 = "https://api.huiying.org/static/v1/"
//export const video_url = "https://v.4rui.cn/m3u8/"
export const audio_url = "https://video.cibei.org/"
export const video_url = "https://video.cibei.org/"
export const video_image_url = "https://video.cibei.org/"
export const videoPageSize = 60;
//文字格式
export function fromatText(text){
    let content = "<p>" + text.replace(/[\n\r]/g,"</p><p>　　") + "</p>";
    return content;
}

//依據編號重構音頻地址
export function getVideoNumUrl(host, num, ext) {
    var url = "";
    if (num != null) {
      var _num = num.split("-");
      url = host + ext + '/' + _num[0] + "/" + _num[0] + "-" + _num[1] + "/";
      if (ext == "m3u8") {
        url = url + num + "/";
      }
      url = url + num + "." + ext;
    }
    return url;
}

//依據編號重構音頻地址
export function getRadioNumUrl(host, num, ext) {
  var url = "";
  if (num != null) {
    url =
      host + num.substring(1, 5) + "/" + num.substring(5, 7) + "/" + num + ext;
  }
  return url;
}

//判断时间差
export function timeCha(time){
  var timestamp = Date.parse(new Date()); 
  return timestamp - time;
}

export function changetime(value) {
  var secondTime = parseInt(value);// 秒
  var minuteTime = 0;// 分
  var hourTime = 0;// 小时
  if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
      //获取分钟，除以60取整数，得到整数分钟
      minuteTime = parseInt(secondTime / 60);
      //获取秒数，秒数取佘，得到整数秒数
      secondTime = parseInt(secondTime % 60);
      //如果分钟大于60，将分钟转换成小时
      if(minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
      }
  }
  var time = parseInt(secondTime);
  if(hourTime){
      time = hourTime + ":";
  }
  if(secondTime < 10){
      time = "0" + parseInt(secondTime);
  }
  if(minuteTime < 10) {
      time = "0" + parseInt(minuteTime) + ":" + time;
  }else{
      time = parseInt(minuteTime) + ":" + time;
  }
  return time;
}

export function getUrl(item) {
  if(item.model=='info'){
    return `/info/view/${item.id}`
  }
  if(item.model=='essence'){
    return `/essence/view/${item.id}`
  }
  if(item.model=='video'){
    return `/video/player/${item.cate_id}/${item.item_id}`
  }
  if(item.model=='radio'){
    return `/radio/player/${item.cate_id}/${item.item_id}`
  }
}