<template>
  <main>
    <div class="cg-search-content">
      <div class="cg-search">
          <span class="cg-search-title">搜索</span>
          <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select cg-search-input" clearable>
            <el-button slot="append" icon="el-icon-search" @click="onSubmit"></el-button>
          </el-input>
      </div>
      <div class="cg-tag tag-group">
        <span class="tag-group__title">分類：</span>
        <el-tag v-bind:class="[listQuery.sid == item.cate_id ? tagSelectClass : '', tagClass]" effect="plain" v-on:click="gotoCate(index)"              
          v-for="(item, index) in cateList"
          :key="index">
            {{ item.title }}     
        </el-tag>
      </div>
    </div>
    <template>
      <div>
        <el-divider><i class="el-icon-loading"></i></el-divider>
      </div>
    </template>
    <div class="cg-infolist">
        <template>
          <div class="cg-infolist-title">
            <span class="">{{ title }}</span>
          </div>
        </template>
        <div class="">
          <el-row>
            <el-col :span="6" 
            v-for="(item, index) in list" 
            :key="index">
              <router-link target="_blank" :to="{ path: `/video/player/${item.id}` }">
                <el-card :body-style="{ padding: '0px' }" shadow="hover" class="cg-infolist-card">
                  <img :src="item.cover" class="image">
                  <div>
                    <span class="cg-infolist-card-title">{{ item.title }}</span>
                    <div class="bottom clearfix">
                      <time class="cg-infolist-card-time">{{ item.date }}</time>
                      <div class="cg-infolist-card-modelname">共{{ item.total }}集</div>
                    </div>
                  </div>
                </el-card>
              </router-link>
            </el-col>
          </el-row>
        </div>
    </div>
    <div class="app-pagebar">
      <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        :page-sizes="pageSizeList"
        @pagination="changePage" />
    </div>
  </main>
</template>

<script>
import { getCategoryList, getAlbumList } from '@/common/api_cg';
import Pagination from '@/components/Pagination'
export default {
  components: { Pagination },
  name: 'VideoIndex',
  data(){
    return{
      title : '',
      tagSelectClass : 'cg-tag-item-select',
      tagClass : 'cg-tag-item',
      keyword: '',
      activePage:0,
      cateList:[],
      list:[],
      isLoading:true,
      loadPage:false,
      refreshing:false,
      page:1,
      listQuery: {
        page: 1,
        pages: 1,
        limit: 20,
        sid:0
      },
      pageSizeList : [10, 20, 50, 100],
      total: 0,
    };
  },
  methods: {
    gotoCate(index) {
      this.isLoading = true
      this.listQuery.page = 1
      this.listQuery.sid =  this.cateList[index].cate_id
      this.title = this.cateList[index].title
      this.list = []
      this.fetchData()
    },
    fetchData(){
      this.list = []
      getAlbumList(this.listQuery).then(res => {
          for (let i = 0; i < res.data.rows.length; i++) {
            this.list.push(res.data.rows[i]); 
          }  
          this.listQuery.pages = res.data.page.pages
          this.isLoading = false
          this.loadPage = false
          this.total = res.data.page.total
      })   
      console.log(this.total)
    },
    changePage(params) {
      this.listQuery.page = params.page
      this.listQuery.limit = params.limit
      this.fetchData();
    },
    onSubmit() {
      if(this.keyword){
        this.listQuery.keyword = this.keyword
        this.fetchData()
      }
    }
  },
  created(){
      if(this.$route.params.num){
          this.album.num = this.$route.params.num
      }
      getCategoryList({}).then(res => {
          this.cateList = res.data.rows
          this.listQuery.sid =  this.cateList[this.activePage].cate_id
          this.title = this.cateList[this.activePage].title
          this.fetchData()
      })
  },
    metaInfo: {
    title: '視頻-慈光讲堂',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' }
    ],
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
