<template>
  <div class="cg-audio" v-if="isLoading">
    <div class="aplyer">
      <div class="cg-audio-content cg-row">
        <div class="cg-audio-cover">
          <el-avatar
            :size="200"
            :src="playItem.cover_square"
          >
            <img :src="playItem.cover_square" class="an" />
          </el-avatar>
        </div>
        <div class="cg-audio-info cg-row">
          <div class="cg-audio-info-title">{{ playItem.title }}</div>
          <div class="cg-audio-info-control"> 
              <div class="auido-play">
                <i
                  v-bind:class="[isPlay ? 'el-icon-video-pause' : 'el-icon-video-play']"
                  @click="startPlayOrPause"
                ></i>
              </div>
              <div class="progress">
                <el-row>
                    <vue-plyr ref="plyr" :options="options">
                      <audio controls crossorigin playsinline></audio>
                    </vue-plyr>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="6">{{ audio.currentTime }}</el-col>
                  <el-col :span="6" class="duration">{{ audio.duration }}</el-col>
                </el-row>
              </div>
          </div>
          <div class="cg-audio-info-catename cg-row">{{ album.title }}</div>
          <div class="cg-audio-info-desc">{{ playItem.desc }}</div>
          <div class="cg-audio-tools">
            <ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cg-album cg-row">
      <div class="cg-path">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <a href="/">電台</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{ album.title }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ playItem.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="cg-album-list">
        <template>
          <el-table
            :data="itemList"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            type="index"
            @row-click="trClick"
          >
            <el-table-column label width="100">
              <template slot-scope="scope">
                <i
                  v-bind:class="[scope.row.id == playItem.id & isPlay? 'el-icon-video-pause playing' : 'el-icon-video-play playing', 'album-list-pay']"
                ></i>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="編號" width="150"></el-table-column>
            <el-table-column prop="title" label="標題"></el-table-column>
          </el-table>
        </template>
        <div class="app-pagebar">
          <pagination
            :total="album.total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            :page-sizes="pageSizeList"
            @pagination="changePage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRadioList } from '@/common/api_cg';
import Pagination from '@/components/Pagination'
import { changetime } from '@/common/tools';
export default {
  name: "AduioPlayer",
  components: { Pagination },
  data() {
    return {
      isLoading: false,
      audio: {
        // 该字段是音频是否处于播放状态的属性
        playing: false,
        // 音频当前播放时长
        currentTime: 0,
        // 音频最大播放时长
        duration: 0,
        progress: 0
      },
      options:{
        controls : ['progress'],
      },
      isPlay:false,
      num:0,
      current_page: 1,
      page_size: 10,
      list:[{id:0,num:'0',title:''}],
      playItem:{
        title:'',
        id :0,
      },
      album:{
        title:'',
        total:0,
      },
      sort:'desc',
      itemList:[],
      listQuery: {
        page: 0,
        pages: 1,
        limit: 20,
      },
      pageSizeList : [10, 20, 50, 100],
    };
  },
  // watch: {
  //   $route(to, fm) {
  //     if (to.path !== fm.path) {
  //       this.album.id = to.params.sid;
  //       this.playItem.id = to.params.itemid;
  //       this.getData();
  //     }
  //   }
  // },
  mounted () {
    // this.$refs.plyr.player.on('ready', (event) => {
    //   console.log(event)
    //   //this.$refs.plyr.player.play();
    // });
    // this.$refs.plyr.player.on('ended', (event) => {
    //   console.log(event)
    // });
  },
  methods: {
    download() {
      let url = this.playItem.url;
      let file_name = this.playItem.title;
      this.axios
        .get(url, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file_name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    tableRowClassName({ rowIndex }) {
      if (this.list[rowIndex].id == this.playItem.id) {
        return "warning-row";
      }
    },
    trClick(row) {
      this.updateInfo(row.id,true)
    },
    // 播放音频
    play(){
      this.$refs.plyr.player.source = {
        type: 'audio',
        title: this.playItem.title,
        sources: [
          {
            src: this.playItem.radio_url,
            type: 'audio/mp3',
          },
        ],
      };
      this.isPlay = !this.isPlay
      if(this.isPlay){
        this.$refs.plyr.player.play();
      }else{
        this.$refs.plyr.player.pause();
      }
    },
    startPlayOrPause(){
      this.play()
    },
    countDownTimer () { 
      setTimeout(() => {
        if(this.isLoading){
          if(this.$refs.plyr.player){
            console.log('---' + this.$refs.plyr.player.currentTime)
            this.audio.currentTime = changetime(this.$refs.plyr.player.currentTime)
            this.audio.duration = changetime(this.$refs.plyr.player.duration)
          }
        }
        this.countDownTimer()
      }, 1000)
    }, 
    updateInfo(id,isAuto) {
      if(isAuto || id != 0){
        for (let i = 0; i < this.itemList.length; ++i) {
          if(id == this.itemList[i].id){
            this.listQuery.page = parseInt(i / this.listQuery.limit);
            this.playItem =this.itemList[i]
            this.playItem.index = i
            continue;
          }
        }
      }else{
        this.playItem =this.itemList[0]
      }
      
      this.isPlay = false
      if(isAuto){
        this.play()
      }
    },
    changePage(){
        this.itemList = [];
        var start = this.listQuery.page * this.listQuery.limit;
        var end = (start + this.listQuery.limit) < this.list.length ? start + this.listQuery.limit : this.list.length;
        for(var i=start; i< end; i++){
            this.itemList.push(this.list[i]); 
        } 
    },
    getData() {
      getRadioList({aid:this.album.id,sort:this.sort}).then(res => {
        this.isLoading = false
        this.list = res.data.rows
        this.album = res.data.album
        this.changePage()
        if(this.playItem.id){
          for (let i = 0; i < this.list.length; ++i) {        
            if(this.playItem.id == this.list[i].id){
              this.listQuery.page = parseInt(i / this.listQuery.limit);
              continue;
            }       
          }
        }
       
        this.updateInfo(this.playItem.id,false)
        this.isLoading = true;
      })  

      this.countDownTimer()
    }
  },
  created() {
    //獲取路由ID
    if (this.$route.params.aid) {
      this.album.id = this.$route.params.aid;
    }
    if (this.$route.params.id) {
      this.playItem.id = this.$route.params.id;
    }

    this.getData();
  },
  beforeDestroy() {
    this.isLoading = false
  },
  metaInfo: {
    title: "電台-今現在說法",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
      }
    ]
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#sprite-plyr{
  display: none;
}
.cg-audio {
  background-color: #fbfbfb;
  padding: 0 100px 30px 100px;
  margin-bottom: 30px;
}
.cg-audio-content {
  padding-top: 50px;
}
.cg-audio-cover {
  width: 200px;
  float: left;
  padding-left: 150px;
  text-align: center;
}
.cg-audio-info {
  width: 650px;
  padding-left: 80px;
  float: left;
}
.cg-audio-info-control {
  height: 50px;
  line-height: 50px;
}
.auido-play {
  float: left;
  height: 80px;
  line-height: 80px;
  font-size: 40px;
  width: 60px;
  color: #606266;
  transition: color 0.15s linear;
  cursor: pointer;
}
.el-icon-caret-left,
.el-icon-caret-right {
  float: left;
  height: 80px;
  line-height: 80px;
  font-size: 32px;
  color: #606266;
  transition: color 0.15s linear;
  cursor: pointer;
}
.progress {
  float: left;
  width: 550px;
  padding: 22px 0px;
}
.duration{
  text-align: right;
}
.auido-play .selected {
  color: #f56c6c;
}
.cg-audio-info-catename {
  font-size: 14px;
  padding-left: 60px;
  padding-bottom: 10px;
}
.cg-audio-info-desc {
  font-size: 14px;
  padding-left: 60px;
}
.cg-path {
  display: block;
  padding: 20px 0px 20px 20px;
  background-color: #ffff;
  margin-bottom: 5px;
}
.cg-album {
  width: 900px;
  margin: 30px 30px 0px 140px;
}
.cg-album-list {
  padding: 0px 20px 20px 20px;
  background-color: #ffff;
}
.cg-audio-info-title {
  font-size: 20px;
  padding-left: 60px;
}
.album-list-pay {
  font-size: 30px;
  cursor: pointer;
}
.el-table tr.selected {
  color: #fff;
  background-color: #436ab3 !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  cursor: pointer;
  color: #fff;
  background-color: #6fa3d9 !important;
}

.el-table .success-row {
  background: #f0f9eb;
}
.warning-row {
  background: oldlace !important;
  color: red;
  /* color: #f56c6c;*/
}
.page {
  margin-top: 50px;
  text-align: center;
}
.cg-audio-tools {
  margin-top: 30px;
  text-align: center;
}
.cg-audio-tools li {
  width: 10%;
  cursor: pointer;
  margin-right: 10px;
  padding: 3px 5px;
  text-align: center;
  border: 1px solid #eee;
  box-shadow: 4px 4px rgba(128, 128, 128, 0.1);
  display: inline-block;
}
.favorite {
  color: red;
}
</style>
